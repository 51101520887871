import React, { Component } from 'react';
import { Link  } from 'react-router-dom';
import axios from 'axios';
import Countdown from "react-countdown";
import '../static/css/ComingSoon.css';
import logo from '../static/images/images/a-logo-transperent.png';
import Instagram from '../static/images/images/instagram.svg';
import Facebook from '../static/images/images/facebook.svg';
import Twitter from '../static/images/images/square-x-twitter.svg';
import Pinterest from '../static/images/images/pinterest.svg';
class ComingSoon extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            empty: null,
            exists: null,
            valid: null,
        };
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    };
    onChangeEmail(event) { this.setState({ email: event.target.value, }) };
    async onSubmit(event) {
        event.preventDefault();
        if (this.state.email.length > 0) {
            const email = {
                email: this.state.email,
            };
            await axios.post(process.env.REACT_APP_SERVER_API + '/notify', email)
                .then(() => { alert("Email has been submitted, we will notify you as soon as we go live! Keep an eye out on your email for updates while you wait for us to go live! Thank you for your interest! 😁") })
                .then(() => { this.setState({ email: '', empty: null, valid: null, exists: null }); })
                .catch( (err) => { 
                    this.setState({ 
                        exists: err.response.data.email_exists!==undefined?err.response.data.email_exists:null, 
                        valid: err.response.data.email_valid!==undefined?err.response.data.email_valid:null,
                        empty: err.response.data.email_required!==undefined?err.response.data.email_required:null,
                    });
                });
        } else {
            this.setState({ empty: "Please enter your EMAIL!", valid: null, exists: null })
        };
    };
    render() {
        // const Completionist = () => <span style={{color: "#85C7DD", fontSize: "20px"}}>We will be live shortly!</span>;
        const renderer = ({ days, hours, minutes, seconds, completed }) => {
            if (completed) {
            //   return <Completionist />;
                return <p style={{color: "#ED9594", fontSize: "20px"}}>Keep an eye out on your inbox for special announcements <span role="img" aria-label="">💝</span></p>
            } else {
              return (
                <span style={{width: "100%"}}>
                    <h2 className="welcomecs-countdown-style">{days} <br/> <span style={{fontSize:"12px"}}>Days</span> </h2><h2 className="welcomecs-countdown-style">:</h2><h2 className="welcomecs-countdown-style">{hours} <br/> <span style={{fontSize:"12px"}}>Hours</span> </h2><h2 className="welcomecs-countdown-style">:</h2><h2 className="welcomecs-countdown-style">{minutes} <br/> <span style={{fontSize:"12px"}}>Minutes</span> </h2><h2 className="welcomecs-countdown-style">:</h2><h2 className="welcomecs-countdown-style">{seconds} <br/> <span style={{fontSize:"12px"}}>Seconds</span></h2>
                </span>
              );
            }
        };
        let empty;
        let exists;
        let valid;
        empty = <p className="error">{this.state.empty}</p>
        exists = <p className="error">{this.state.exists}</p>
        valid = <p className="error">{this.state.valid}</p>
        return (
            <div className="container">
                <div className="main-image-container">
                <div className="animates-logo-container"><img className="animates-logo hover" style={{width: "125px"}} src={logo} alt="" /></div>               
                    <div className="welcomecs-intro-content-container"><br/>
                        <p style={{letterSpacing: "5px"}}>Meets. Dates. Anime.</p><br/>
                        <h1 className="">Something special<br />is in the works :)</h1>
                        <br/>
                        <p>Coming Soon 2025 <span role="img" aria-label="">🚀</span></p>
                        {/* <p>Keep an eye out in your inbox for a special announcement <span role="img" aria-label="">💝</span></p> */}
                        <br/>
                        <Countdown date={1715108400000} renderer={renderer} /><br /><br />
                        <p>Get ready to level up your love life with our upcoming dating app, AniMates! Dive into the colorful world of anime culture and let your passions unite with like-minded enthusiasts. Find your 'kawaii' companion who shares your favorite hobbies and embark on epic dating adventures!</p>
                    </div>
                    <div className="welcomecs-email-launch-notification-form-container">
                        <p>Enter your email to get notified: <br /> <span style={{fontSize: "10px"}}>(We promise to never spam you. <span role="img" aria-label="">🙂</span>)</span></p>
                        { empty } { exists } { valid }
                        <form onSubmit={this.onSubmit}>
                            <input className="welcomecs-email-launch-notification-form-container-input" type="text" placeholder="Enter email..." value={this.state.email} onChange={this.onChangeEmail}/>
                            <button type="submit">Notify Me</button>
                        </form>
                    </div><br/><br/>
                    <div className="welcomecs-footer-follow-soon-container">
                        <p className="welcomcs-special-message" style={{color: "white", margin: "0px auto", marginBottom: "-30px", cursor: "default"}}>We will be running a special pre-launch offer available to everyone until launch day! Take advantage of this exclusive LIMITED TIME offer! Click on the button below to learn more!</p>
                        <Link to="/signup"><button className="upcoming-event-section-button" style={{marginTop: "50px"}}><span role="img" aria-label="Exclusive membership offer">🔥</span> PRE-LAUNCH OFFER <span role="img" aria-label="Exclusive membership offer">🔥</span></button></Link>
                        <div className="welcomecs-footer-follow-soon-icons-container">
                            <h4 className="welcomecs-footer-follow-soon-icons-title">GIVE US A FOLLOW</h4>
                            <div className="welcomecs-footer-follow-soon-icons">
                                <a className="welcomecs-footer-follow-soon-icon" href="https://www.instagram.com/animates_app/" target="_blank" rel="noopener noreferrer"><img className="welcomecs-footer-follow-social-icon" src={Instagram} alt="animates's Instagram link for anime enthusists looking for dates"/></a> 
                                <a className="welcomecs-footer-follow-soon-icon" href="https://www.facebook.com/AniMatesApp/" target="_blank" rel="noopener noreferrer"><img className="welcomecs-footer-follow-social-icon" src={Facebook} alt="animates's Facebook page link for anime enthusists looking for dates"/></a> 
                                <a className="welcomecs-footer-follow-soon-icon" href="https://twitter.com/AniMatesApp" target="_blank" rel="noopener noreferrer"><img className="welcomecs-footer-follow-social-icon" src={Twitter} alt="animates's Twitter link for anime enthusists looking for dates"/></a> 
                                <a className="welcomecs-footer-follow-soon-icon" href="https://www.pinterest.com/AniMatesApp" target="_blank" rel="noopener noreferrer"><img className="welcomecs-footer-follow-social-icon" src={Pinterest} alt="animates's Pinterests profile link for anime enthusists looking for dates"/></a> 
                            </div>
                        </div>
                    </div>
                    <p className="welcomecs-footer-bottom-title" style={{color: "white"}}>
                        <span className="welcomecs-footer-bottom-title-title w">2024 AniMates LLC</span>
                        <Link to={"/privacy"} className="welcomecs-footer-bottom-title-link no-dec">Privacy</Link>
                    </p>
                </div>
            </div>
        );
    };
};
export default ComingSoon;
