import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; 
// import { BrowserRouter as Router, Route } from 'react-router-dom'; 
import './App.css';
import ComingSoon from './components/ComingSoon';
import Privacy from './components/Privacy';
import Cookies from './components/Cookies';
import TermsOfService from './components/TermsOfService';
import SignUp from './components/SignUp'
import SignUpInfo from './components/SignUpInfo'
import SignUpConfirmation from './components/SignUpConfirmation'

class App extends Component {
  constructor() {
    super();
    this.state = {};
  };
  render() {
    return (
      <BrowserRouter>

          <Routes>
          
            <Route path="/" exact element={ <ComingSoon /> } />

            <Route path="/coming-soon" exact element={ <ComingSoon /> } />

            <Route path="/terms-of-service" exact element={ <TermsOfService /> } />

            <Route path="/privacy" exact element={ <Privacy /> } />

            <Route path="/cookies" exact element={ <Cookies /> } />

            <Route path="/signup" exact element={ <SignUp /> } />

            <Route path="/signup-info/:plan/:success" exact element={ <SignUpInfo /> } />

            <Route path="/signup-confirmation" exact element={ <SignUpConfirmation /> } />
        
        </Routes>

      </BrowserRouter>
    );
  };
};
export default App;