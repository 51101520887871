import React, { Component } from 'react';
import axios from 'axios';
import '../static/css/SignUpInfo.css';
import { State, City }  from 'country-state-city';
import logo from '../static/images/images/a_logo.jpg';
import { Link } from 'react-router-dom';
const url = window.location.href.split('/');
class SignUpInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            ui: null,
            full_name: '',
            email: '',
            password: '',
            confirm_password: '',
            display_name: null,
            city: '',
            states: '',
            full_name_required: '',
            full_name_min: '',
            full_name_max: '',
            full_name_valid: '',
            email_required: '',
            email_valid: '',
            email_exists: '',
            email_direct: '',
            email_direct_link: null,
            password_required: '',
            password_min: '',
            password_special_character: '',
            password_number: '',
            confirm_password_required: '',
            confirm_password_match: '',
            display_name_error: '',
            display_name_exists_error: '',
            display_name_max_error: '',
            state_city_error: '',
            signed_in: localStorage.getItem('token') ? true : false,
            payment: null,
            redirect: false,
            email_exists_gmail: null,
            session_id: url[url.length-1].split('=')[1],
            customer_id: null,
            monthly: false,
            six_months: false,
            yearly: false,
            usStates: State.getStatesOfCountry('US'),
            usStateCities: '',
        };
        this.onChangeFullName = this.onChangeFullName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onChangeStates = this.onChangeStates.bind(this);
        this.onChangeDisplayName = this.onChangeDisplayName.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    };
    async componentDidMount () {
        if (this.state.email === null) window.location = "/signup"
        await axios.post(process.env.REACT_APP_SERVER_API + `/auth/pre_signup_plan_session/${this.state.session_id}`)
            .then( (response) => { 
                if (response.data.exists === false) {
                    console.log(url[url.length-2], "the url")
                    if (url[url.length-2] === "monthly") this.setState({ customer_id: response.data.id, email: response.data.email, isLoading: false, monthly: true });
                    if (url[url.length-2] === "six_months") this.setState({ customer_id: response.data.id, email: response.data.email, isLoading: false, six_months: true });
                    if (url[url.length-2] === "yearly") this.setState({ customer_id: response.data.id, email: response.data.email, isLoading: false, yearly: true });
                } else {
                    alert("You have already created your account, please await for launch date to make profile updates. Please contact us at hello@animates.app for any questions or concerns!")
                    window.location = "/coming-soon"
                }
            })
            .catch(()=> {this.setState({ isLoading: false, })});
    };
    onChangeFullName(event) {
        this.setState({
            full_name: event.target.value
        });
    };
    onChangeEmail(event) {
        this.setState({
            email: event.target.value
        });
    };
    onChangePassword(event) {
        this.setState({
            password: event.target.value
        });
    };
    onChangeConfirmPassword(event) {
        this.setState({
            confirm_password: event.target.value
        });
    };
    onChangeCity(event) {
        this.setState({
            city: event.target.value
        });
    };
    onChangeStates(event) {
        this.setState({
            states: event.target.value,
            usStateCities: City.getCitiesOfState('US', event.target.value),
        });
        if (this.state.states === "Select State") {
            this.setState({ city: "Select City" })
        }
    };
    onChangeDisplayName(event) {
        this.setState({
            display_name: event.target.value
        });
    };
    validLetters = (full_name) => {
        return /^[A-Za-z_ ]+$/.test(full_name);
    }
    validOnlyNumbers = (full_name) => {
        return /\d/.test(full_name);
    }
    validNumbers = (zip_code) => {
        return /^[0-9]+$/.test(zip_code);
    }
    validPasswordNumber = (password) => {
        return /\d/.test(password);
    }
    validPasswordSpecialCharacter = (password) => {
        return /^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/.test(password);
    }
    validAlphaNumeric = (display_name) => {
        return /^[A-Za-z0-9]*$/.test(display_name);
    }
    async onSubmit(event) {
        event.preventDefault();
        if (this.state.email === '') {
            alert("You have already created your account, please await for launch date to make profile updates. Please contact us at hello@animates.app for any questions or concerns!");
            window.location = "/coming-soon";
        }
        const user = {
            full_name: this.state.full_name,
            email: this.state.email,
            password: this.state.password,
            confirm_password: this.state.confirm_password,
            city: this.state.city,
            states: this.state.states,
            display_name: this.state.display_name,
            monthly: this.state.monthly,
            six_months: this.state.six_months,
            yearly: this.state.yearly,
            customer_id: this.state.customer_id
        };
        if (this.state.full_name === null || this.state.full_name === '' || this.state.full_name.length < 1) {
            this.setState({full_name_required: "NAME required!"})
        } else {
            this.setState({full_name_required: ''})
        }
        if (this.state.full_name.length !== 0 && this.state.full_name.length < 2) {
            this.setState({full_name_min: "NAME must contain a minimum of 2 characters!"})
        } else {
            this.setState({full_name_min: ''})
        }
        if (this.state.full_name.length > 45) {
            this.setState({full_name_max: "NAME cannot contain more than 45 characters!"})
        } else {
            this.setState({full_name_max: ''})
        }
        if (this.state.full_name.length !== 0 && !this.validLetters(this.state.full_name) ) {
            this.setState({full_name_valid: "NAME must contain letters only!"})
        } else {
            this.setState({full_name_valid: ''})
        }
        if (this.state.password === null || this.state.password === '' || this.state.password.length < 1) {
            this.setState({password_required: "PASSWORD required!"});
        } else {
            this.setState({password_required: ""});
        }
        if ((this.state.password.length > 0 && this.state.password.length <= 7) || (this.state.password.length > 0 && this.state.password.length > 15)) {
            this.setState({password_min: "PASSWORD can only be between 8-15 characters!"});
        } else {
            this.setState({password_min: ""});
        }
        if (this.state.password.length >= 8 && !this.validPasswordSpecialCharacter(this.state.password)) {
            this.setState({password_special_character: "PASSWORD must contain at least one special character! (!, @, #, $, %, ^, &, *)"});
        } else {
            this.setState({password_special_character: ""});
        }
        if (this.state.password.length >= 8 && !this.validPasswordNumber(this.state.password)) {
            this.setState({password_number: "PASSWORD must contain at least one number!"});
        } else {
            this.setState({password_number: ""});
        }
        if (this.state.confirm_password === null || this.state.confirm_password === '' || this.state.confirm_password.length < 1) {
            this.setState({confirm_password_required: "CONFIRM PASSWORD required!"});
        } else {
            this.setState({confirm_password_required: ""});
        }
        if (this.state.confirm_password !== null && this.state.password.localeCompare(this.state.confirm_password) === 1) {
            this.setState({confirm_password_match: "PASSWORDS don't match!"});
        } else {
            this.setState({confirm_password_match: ""});
        }
        if (this.state.display_name !== null && this.state.display_name.length > 15) {
            this.setState({display_name_max_error: "DISPLAY NAME can only contain more than 15 characters!"});
        } else {
            this.setState({display_name_max_error: ""});
        }
        if (!this.validAlphaNumeric(this.state.display_name)) {
            this.setState({display_name_error: "DISPLAY NAME can only contain letters and numbers with no spaces!"});
        } else {
            this.setState({display_name_error: ""});
        }
        if (this.state.states === '' || this.state.states === 'Select State' || this.state.city === '' || this.state.city === 'Select City') {
            this.setState({ city: ""})
            this.setState({ state_city_error: "You must select STATE and CITY!"})
        } else {
            this.setState({ isLoading: true, })   

            await axios.post(process.env.REACT_APP_SERVER_API + '/auth/pre_signup_profile', user)
                .then(response => { 
                    if (response.data === "SUCCESS") {
                        window.location = "/signup-confirmation"
                    }
                })
                .catch(err => {
                    this.setState({
                        full_name_required: err.response.data.full_name_required,
                        full_name_min: err.response.data.full_name_min,
                        full_name_max: err.response.data.full_name_max,
                        full_name_valid: err.response.data.full_name_valid,
                        password_required: err.response.data.password_required,
                        password_min: err.response.data.password_min,
                        password_special_character: err.response.data.password_special_character,
                        password_number: err.response.data.password_number,
                        confirm_password_required: err.response.data.confirm_password_required,
                        confirm_password_match: err.response.data.confirm_password_match,
                        state_city_error: err.response.data.states_error,
                        display_name_error: err.response.data.display_name_error,
                        display_name_max_error: err.response.data.display_name_max_error,
                        display_name_exists_error: err.response.data.display_name_exists_error,
                        isLoading: false,
                    });
                });
        }
    };

    us_states() {
        const USState = (props) => (
            <option>{props.state.isoCode}</option>
        );
        return this.state.usStates.map(state => {
            return  <USState state={state} key={state.isoCode} />
        });
    };

    us_states_cities() {
        const USStateCities = (props) => (
            <option style={{textAlign: "center"}}>{props.city.name}</option>
        );
        return this.state.usStateCities.map(city => {
            return  <USStateCities city={city} key={city.name} />
        });
    };

    render() {
        console.log(this.state, "****")

        let email_exists;
        if (this.state.email_exists) {
            email_exists = <p className="signup-form-error-message">{ this.state.email_exists }</p>

        } else {
            email_exists = null
        }
        let states = this.us_states();
        let cities = this.state.usStateCities === '' ? <option disabled>Select State First</option> : this.us_states_cities();
        const body = this.state.isLoading?"Loading":
        <div>
            <div className="animates-logo-container"><Link to={'/coming-soon'}><img className="animates-logo hover" src={logo} alt="" /></Link></div>               
            <div className="signup-info-box">
                <h3 className="signup-info-title" style={{marginBottom: "50px"}}><u>Profile Details</u></h3>
                <form onSubmit={this.onSubmit}>
                    <p className="signup-message"><u>ATTENTION:</u> Please do not leave page, finish your profile details and reserve your unique display name. You will not be able to return later to do this and will have to wait until February 6, 2023 to complete this.</p><br/><br/>
                    <p className="signup-form-error-message">{ this.state.full_name_required }</p>
                    <p className="signup-form-error-message">{ this.state.full_name_min }</p>
                    <p className="signup-form-error-message">{ this.state.full_name_max }</p>
                    <p className="signup-form-error-message">{ this.state.full_name_valid }</p>
                    <input className="form-input" type="text" placeholder="Full Name" value={this.full_name} onChange={this.onChangeFullName}/>
                    <p className="signup-form-error-message">{ this.state.display_name_error }</p>
                    <p className="signup-form-error-message">{ this.state.display_name_max_error }</p>
                    <p className="signup-form-error-message">{ this.state.display_name_exists_error }</p>
                    <input className="form-input" type="text" placeholder="Display Name (Optional)" value={this.display_name} onChange={this.onChangeDisplayName}/>
                    <p className="signup-form-error-message">{ this.state.email_required }</p>
                    <p className="signup-form-error-message">{ this.state.email_valid }</p>
                    {email_exists}
                    <input className="form-input" type="text" placeholder={this.state.email} value={this.state.email} onChange={this.onChangeEmail} style={{cursor: "not-allowed"}} disabled/>
                    <p className="signup-form-error-message">{ this.state.password_required }</p>
                    <p className="signup-form-error-message">{ this.state.password_min }</p>
                    <p className="signup-form-error-message">{ this.state.password_number }</p>
                    <p className="signup-form-error-message">{ this.state.password_special_character }</p>
                    <input className="form-input" type="password" placeholder="Password" value={this.password} onChange={this.onChangePassword}/>
                    <p className="signup-form-error-message">{ this.state.confirm_password_required }</p>
                    <p className="signup-form-error-message">{ this.state.confirm_password_match }</p>
                    <input className="form-input" type="password" placeholder="Confirm Password" value={this.confirm_password} onChange={this.onChangeConfirmPassword}/>
                    <p className="signup-form-error-message">{ this.state.state_city_error }</p>
                    <select className="form-input" value={this.state.states} onChange={this.onChangeStates}>
                        <option>Select State</option>
                        {states}
                    </select>
                    <p className="signup-form-error-message">{ this.state.city_error }</p>
                    <select className="form-input" value={this.state.city} onChange={this.onChangeCity}>
                        <option>Select City</option>
                        {cities}
                    </select>
                    <button className="form-button">Create</button>
                </form>
            </div>

            <p className="signup-info-footer">2024 AniMates LLC</p>
        </div>
        return (
            <div className="signup-info-container" style={{ fontSize: "10px" }}>
                { body }
            </div>
        );
    };
};
export default SignUpInfo;